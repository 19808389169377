import React, { useState, useEffect } from 'react';
import SockJsClient from 'react-stomp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Moment from 'react-moment';
import 'moment-timezone';
// import usePushNotifications from './usePushNotifications';

function App() {
  const server = 'ec2-3-254-177-150.eu-west-1.compute.amazonaws.com:8080';
  //const server = "localhost:8080";
  const url = 'http://' + server + '/websocket-chat/';
  const [user, setUser] = useState(null);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [typedMessage, setTypedMessage] = useState('');
  const [typedPassword, setTypedPassword] = useState('');
  const [client, setClient] = useState(Object);
  const [messages, setMessages] = useState([]);
  const inputRef = React.useRef();

  // const {
  //   userConsent,
  //   pushNotificationSupported,
  //   userSubscription,
  //   onClickAskUserPermission,
  //   onClickSusbribeToPushNotification,
  //   onClickSendSubscriptionToPushServer,
  //   pushServerSubscriptionId,
  //   onClickSendNotification,
  //   error,
  //   notificatyionsLoading,
  // } = usePushNotifications();

  const sendMessage = () => {
    if (typedMessage === '') {
      inputRef.current.focus();
      return;
    }
    client.sendMessage(
      '/app/user-all',
      JSON.stringify({
        name: user,
        message: typedMessage,
        token: token,
      })
    );
    setTypedMessage('');
    inputRef.current.focus();
  };

  const ver = () => {
    sendLogin(true);
  };

  const displayMessages = () => {
    return (
      <div>
        <div className='mb-2'>
          <Button variant='contained' color='primary' onClick={cargarHistoria}>
            cargar más
          </Button>
        </div>
        {messages.map((msg, i) => {
          return user === msg.name ? (
            <div key={i} className='flex items-start mb-4 text-sm'>
              <div className='ml-auto mr-1 p-6 max-w-sm bg-white rounded-xl shadow-md flex items-center space-x-4'>
                <div className='flex-1 overflow-hidden'>
                  <div>
                    <span className='text-s font-thin'>{msg.name}</span>
                    <span className='text-grey font-thin text-xs ml-2'>
                      <Moment format='HH:mm DD/MM'>{msg.sent}</Moment>
                    </span>
                  </div>
                  <p className='text-grey-800 leading-normal'>{msg.message}</p>
                </div>
              </div>
            </div>
          ) : (
            <div key={i} className='flex items-start mb-4 text-sm'>
              <div className='mr-auto ml-1 p-6 max-w-sm bg-indigo-100 rounded-xl shadow-md flex items-center space-x-4'>
                <div className='flex-1 overflow-hidden'>
                  <div>
                    <span className='text-s font-thin'>{msg.name}</span>
                    <span className='text-grey font-thin text-xs ml-2'>
                      <Moment format='HH:mm DD/MM'>{msg.sent}</Moment>
                    </span>
                  </div>
                  <p className='text-grey-800 leading-normal'>{msg.message}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const downHandler = ({ key }) => {
    if (key === 'Enter' || key === 'NumpadEnter') {
      if (user === null) login();
      if (!visible) ver();
      if (user !== null && visible) sendMessage();
    }
  };

  const onFocus = () => {};

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    setVisible(false);
  };

  const cargarHistoria = () => {
    setLoading(true);
    const historia = messages[0].sent;
    fetch(
      'http://' +
        server +
        '/history/more?moment=' +
        historia +
        '&token=' +
        token
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setMessages(data.concat(messages));
      })
      .catch((e) => {
        setLoading(false);
        console.log('fetch failed: ' + historia);
      });
  };

  const cargaInicial = (initial_token) => {
    setLoading(true);
    fetch('http://' + server + '/history/last?token=' + initial_token)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setMessages(data);
      })
      .catch((e) => {
        setLoading(false);
        console.log('fetch intial data failed');
      });
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('keydown', downHandler);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('keydown', downHandler);
    };
  });

  const sendLogin = (cargar) => {
    setLoading(true);
    fetch('http://' + server + '/login/user?secret=' + typedPassword)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setToken(data.token);
        setUser(data.user);
        setTypedPassword('');
        setVisible(true);
        if (cargar) {
          cargaInicial(data.token);
        }
      })
      .catch((e) => {
        setLoading(false);
        setTypedPassword('');
        setUser(null);
        console.log('fetch failed');
      });
  };

  const login = () => {
    sendLogin(true);
  };

  return (
    <div className='bg-gray-100'>
      {loading && <div>LOADING...</div>}
      {user === null && (
        <div>
          <TextField
            id='outlined-basic'
            label='Enter password'
            variant='outlined'
            autoFocus
            value={typedPassword}
            onChange={(event) => {
              setTypedPassword(event.target.value);
            }}
            inputRef={inputRef}
          />
          <Button variant='contained' color='primary' onClick={login}>
            Login nuevo
          </Button>
        </div>
      )}
      {user !== null && !visible && (
        <div>
          <TextField
            id='outlined-basic'
            label='Enter password'
            variant='outlined'
            autoFocus
            value={typedPassword}
            onChange={(event) => {
              setTypedPassword(event.target.value);
            }}
            inputRef={inputRef}
          />
          <Button variant='contained' color='primary' onClick={ver}>
            Login
          </Button>
        </div>
      )}
      {!loading && visible && user !== null && (
        <div className='flex-1 flex flex-col overflow-hidden'>
          <div className='px-6 py-4 flex-1 overflow-y-scroll'>
            {displayMessages()}
          </div>
          <div className='pb-6 px-4 flex-none'>
            <div className='flex rounded-lg overflow-hidden'>
              <TextField
                type='text'
                className='w-full px-4'
                placeholder='Message'
                autoFocus
                value={typedMessage}
                onChange={(event) => {
                  setTypedMessage(event.target.value);
                }}
                inputRef={inputRef}
              />
            </div>
          </div>
          <Button variant='contained' color='primary' onClick={sendMessage}>
            ENVIAR
          </Button>
        </div>
      )}
      <SockJsClient
        url={url}
        topics={['/topic/user']}
        onConnect={() => {
          console.log('connected');
        }}
        onDisconnect={() => {
          console.log('Disconnected');
        }}
        onMessage={(msg) => {
          var jobs = messages;
          jobs.push(msg);
          setMessages([...jobs]);
        }}
        ref={(client) => {
          setClient(client);
        }}
      />
    </div>
  );
}

export default App;
